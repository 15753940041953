/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

html, body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  margin: 0;
  padding: 0;
}

body{
	background: url('../images/gallery/PICT1303.JPG') no-repeat center center fixed;
	background-size: cover;
}

.mdl-jpf .mdl-layout__header-row {
  padding: 10px 0 0 20px;
}
.mdl-jpf .mdl-layout.is-small-screen .mdl-layout__header-row h3 {
  font-size: inherit;
}
.mdl-jpf .mdl-layout__tab-bar-button {
  display: none;
}
.mdl-jpf .mdl-layout.is-small-screen .mdl-layout__tab-bar .mdl-button {
  display: none;
}
.mdl-jpf .mdl-layout:not(.is-small-screen) .mdl-layout__tab-bar,
.mdl-jpf .mdl-layout:not(.is-small-screen) .mdl-layout__tab-bar-container {
  overflow: visible;
}
.mdl-jpf .mdl-layout__tab-bar-container {
  height: 64px;
}
.mdl-jpf .mdl-layout__tab-bar {
  padding: 0;
  padding-left: 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.mdl-jpf .mdl-layout__tab-bar .mdl-layout__tab {
  height: 64px;
  line-height: 64px;
}
.mdl-jpf .mdl-layout__tab-bar .mdl-layout__tab.is-active::after {
  background-color: white;
  height: 4px;
}
.mdl-jpf main > .mdl-layout__tab-panel {
  padding: 8px;
  padding-top: 48px;
}
.mdl-jpf .mdl-card {
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.mdl-jpf .mdl-card > * {
  height: auto;
}
.mdl-jpf .mdl-card .mdl-card__supporting-text {
  margin: 40px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0;
  color: inherit;
  width: calc(100% - 80px);
}
.mdl-jpf.mdl-jpf .mdl-card__supporting-text h4 {
  margin-top: 0;
  margin-bottom: 20px;
}
.mdl-jpf .mdl-card__actions {
  margin: 0;
  padding: 4px 40px;
  color: inherit;
}
.mdl-jpf .mdl-card__actions a {
  color: #00BCD4;
  margin: 0;
}
.mdl-jpf .mdl-card__actions a:hover,
.mdl-jpf .mdl-card__actions a:active {
  color: inherit;
  background-color: transparent;
}
.mdl-jpf .mdl-card__supporting-text + .mdl-card__actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.mdl-jpf #add {
  position: absolute;
  right: 40px;
  top: 36px;
  z-index: 999;
}

.mdl-jpf .mdl-layout__content section:not(:last-of-type) {
  position: relative;
  margin-bottom: 48px;
}
.mdl-jpf section.section--center {
  max-width: 959px;
}
.mdl-jpf #features section.section--center {
  max-width: 620px;
}
.mdl-jpf section > header{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mdl-jpf section > .section__play-btn {
  min-height: 200px;
}
.mdl-jpf section > header > .material-icons {
  font-size: 3rem;
}
.mdl-jpf section > button {
  position: absolute;
  z-index: 99;
  top: 8px;
  right: 8px;
}
.mdl-jpf section .section__circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 1;
      -ms-flex-negative: 1;
          flex-shrink: 1;
}
.mdl-jpf section .section__text {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  padding-top: 8px;
}
.mdl-jpf section .section__text h5 {
  font-size: inherit;
  margin: 0;
  margin-bottom: 0.5em;
}
.mdl-jpf section .section__text a {
  text-decoration: none;
}
.mdl-jpf section .section__circle-container > .section__circle-container__circle {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 8px 0;
}
.mdl-jpf section.section--footer .section__circle--big {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: 8px 32px;
}
.mdl-jpf .is-small-screen section.section--footer .section__circle--big {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 8px 16px;
}
.mdl-jpf section.section--footer {
  padding: 64px 0;
  margin: 0 -8px -8px -8px;
}
.mdl-jpf section.section--center .section__text:not(:last-child) {
  border-bottom: 1px solid rgba(0,0,0,.13);
}
.mdl-jpf .mdl-card .mdl-card__supporting-text > h3:first-child {
  margin-bottom: 24px;
}
.mdl-jpf .mdl-layout__tab-panel:not(#overview) {
  background-color: white;
}
.mdl-jpf #features section {
  margin-bottom: 72px;
}
.mdl-jpf #features h4, #features h5 {
  margin-bottom: 16px;
}
.mdl-jpf .toc {
  border-left: 4px solid #C1EEF4;
  margin: 24px;
  padding: 0;
  padding-left: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.mdl-jpf .toc h4 {
  font-size: 0.9rem;
  margin-top: 0;
}
.mdl-jpf .toc a {
  color: #4DD0E1;
  text-decoration: none;
  font-size: 16px;
  line-height: 28px;
  display: block;
}
.mdl-jpf .mdl-menu__container {
  z-index: 99;
}

.mdl-logo{
	margin: 15px 0 15px 15px;
}


/* override */
.mdl-layout__header-row{
	height: 138px;
}

img {
display: inline-block;
height: auto;
max-width: 100%;
}

.fa {
	font-size: 30px;
	vertical-align: middle;
	text-align: center;
	width: 50px;
}


.mdl-layout__header-row{
	max-width: 975px;
	margin: 0 auto;
}

.mdl-navigation{
	margin: 0px auto;
	width: 100%;
	max-width: 965px;
}

.text{
	padding-left: 20px; 
}

.mdl-color--darkgrey{
	background-color: #bbb;
}

.mdl-color--jpfred{
	background-color: #c13921;
}

.mdl-color-text--jpfred{
	color: #c13921;
}

.mdl-color--transparent{
	background-color: transparent;
}

.centered{
	margin: 0 auto;
}

.mdl-footer{
	display:block;
	padding: 16px;
	color: #9E9E9E;
	background-color: #424242;
	max-width:943px;
	width: 100%;
}

.mdl-footer__only-section{
	max-width: 975px;
}

.mdl-layout__nav-bar{
	padding: 0px 0px 0px 16px;
	box-sizing: border-box;
	max-width: 975px;
	width: 100%;
}


.mdl-navigation__link{
	margin: 0px;
	border: medium none;
	padding: 0px 24px;
	float: left;
	position: relative;
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	text-decoration: none;
	height: 48px;
	line-height: 48px;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
	color: white;
	overflow: hidden;
}

h4{
	margin: 0 0 16px 0;
}

.mdl-navigation-mobile{
	display: none;
}

.toggle{
	display: none;
}

/**** Mobile layout ****/
@media (max-width: 640px) {
	.mdl-navigation{
		display: none;
	}
	
	.mdl-navigation__mobile{
		color: #fff;
		display:block;
		text-align: center;
		text-transform:uppercase;
		padding: 0 16px;
	    position: relative;
	    overflow: hidden;
	  	font-size: 14px;
	  	font-weight: 400;
	  	line-height: 24px;
	  	letter-spacing: 0;
	  	text-decoration: none;
	  	cursor: pointer;
		height: 48px;
		line-height: 48px;
	  	white-space: nowrap;
	}
	#logo{
		display:block;
		margin: auto;
	}
}

.mdl-button{
	color: #fff;
}

.round-corners{
	border-radius: 7px;
	box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.75);
}

.gallery{
	margin: 0 15px 15px 0;
}

.mdl-layout__content{
	max-width:975px;
	width: 100%;
}

#map-canvas {
        height: 400px;
        margin: 0px;
        padding: 0px
      }
